
import { defineComponent } from "vue";

const listener = function () {
	//@ts-ignore
	if (!window.emotes.find((x) => x.name === this.emotename)) {
		//@ts-ignore
		console.warn("Missing emote: ", this.emotename);
	}

	//@ts-ignore
	const found = window.emotes.find((x) => x.name === this.emotename);

	if (found?.text) {
		//@ts-ignore
		this.text = found.text;
	}
	//@ts-ignore
	this.imageURL = found?.url;
};

export default defineComponent({
	props: {
		emotename: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			imageURL: "https://via.placeholder.com/50x50",
			text: "",
		};
	},
	mounted() {
		window.addEventListener("emotes_loaded", listener.bind(this));
		//@ts-ignore
		if (window.emotes) {
			listener.bind(this)();
		}
	},
	unmounted() {
		window.removeEventListener("emotes_loaded", listener);
	},
});
