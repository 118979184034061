import { createApp } from "vue";
import Emote from "./components/Emote.vue";
import App from "./App.vue";

import { defineCustomElement } from "vue";

customElements.define("discord-emote", defineCustomElement(Emote));

fetch("api/get_emotes")
	.then((x) => x.json())
	.then((x) => {
		if (!x.emotes) {
			console.error("Somethign went wrong with loading emotes");
			return;
		}
		//@ts-ignore
		window.emotes = x.emotes;

		window.dispatchEvent(new CustomEvent("emotes_loaded"));
	});

document.title = "Dagboek van een Mod";
createApp(App).mount("#app");
