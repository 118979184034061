import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"font-size":"1.5em"}
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true),
    (!_ctx.text)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.imageURL,
          style: {"width":"2em","height":"2em"}
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ], 64))
}